import PageManager from "../../PageManager";
import ApiProxy from "../../ts/ApiProxy";
import Tools from "../../ts/Tools";
import Components from "../Compoents";
import RwdPicture from "../RwdPicture";
import EZAlert from "../popups/EZAlert";
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";
import MoreWorks from "./MoreWorks";
import { ComponentDefineDic } from "../interfaces";
import { WorkThumbData } from "../index/Work";
import gsap from "gsap";

Components.registComponents
({
  "more-works": MoreWorks
});

let $root: JQuery;

type RwdImage = {
  pc: string,
  mobile: string
};

export type WorkDetailData = {
  title: string
  kv: RwdImage
  feature_list: WorkDetailFeature[]
  media_list: WorkDetailMedia[]
}

export type WorkDetailFeature = {
  label: string
  text: string
}

export type WorkDetailMedia = {
  type: "image" | "youtube",
  image?: RwdImage,
  youtube_id?: string
}


let _workDataDic: {[workId:string]: WorkDetailData} = {},
  _workThumbDataList: WorkThumbData[],
  _currentWorkThumbData: WorkThumbData;

let _isLocking: boolean = false,
  _moreWorks: MoreWorks;

export default class WorkDetail
{
  static init()
  {
    _workThumbDataList = ApiProxy.getStoredData("index/get-work-list").list;

    _workThumbDataList.forEach((obj, index)=>
    {
      obj.index = index;
    });

    $root = PageManager.getPageRoot("work-detail").$page;

    let $container = $root.find(".work-detail-container");
    Components.bindScripts($root[0]);

    _moreWorks = Tools.seekComponentByName("more-works", $root);
    

    this._setupNav();

  }

  private static _setupNav()
  {
    let $nav = $root.find(`[component="work-detail-nav"]`);

    $nav.find(".btn-return").on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      PageManager.toPage("index");
    });

    $nav.find(".btn-prev").on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      // console.log(_currentWorkThumbData.index);
      
      let index = _currentWorkThumbData.index - 1;
      if(index < 0) index = _workThumbDataList.length - 1;
      
      this.toWork(_workThumbDataList[index]);
    });

    $nav.find(".btn-next").on("click", (event:JQuery.ClickEvent)=>
    {
      event.preventDefault();
      let index = _currentWorkThumbData.index + 1;
      if(index >= _workThumbDataList.length) index = 0;
      this.toWork(_workThumbDataList[index]);
    });
  }

  static toIndex(workThumbIndex: number)
  {
    this.toWork(_workThumbDataList[workThumbIndex]);
  }

  static toWork(workThumbData: WorkThumbData)
  {
    let workId = workThumbData.id,
      data = _workDataDic[workId];

    if(data)
    {
      (PageManager.getCurrentPageName() === "work-detail")? this._replaceWork(workThumbData): this._toWork(workThumbData);
    }
    else
    {
      ApiProxy.quickCall(`work-detail/work-${workId}`, {}, true, { useApiSchemaData: false}).then((res) =>
      {
        _workDataDic[workId] = res.data;
        (PageManager.getCurrentPageName() === "work-detail")? this._replaceWork(workThumbData): this._toWork(workThumbData);

      }).catch((res)=>
      {
        EZAlert.alert(res.message, undefined, undefined, false).then(()=>
        {
        });
      })
    }
  }

  private static _replaceWork(workThumbData: WorkThumbData)
  {
    // if(_currentWorkThumbData && _currentWorkThumbData.id === workThumbData.id)
    // {
    //   console.error(`same work id: ${_currentWorkThumbData.id}`);
    //   return;
    // }

    // let $wrapper  = $root.fiond(".work-detail-wrapper");
    if(_isLocking) return; _isLocking = true;

    let $container = $root.find(".work-detail-container"),
      $wrapper = $container.find(">.wrapper");
    
    let duration = $(window).scrollTop() / 2000;
    duration = Math.min(1, duration);
    duration = Math.max(.3, duration);
     
    gsap.to(window, { duration: duration, scrollTo: { y: 0} });

    let tl = gsap.timeline();
    tl.set($container, {height: $wrapper.height()});
    tl.to($wrapper, {duration: .5, opacity: 0, ease: "power1.out"});
    tl.add(()=>
    {
      this._toWork(workThumbData, false);
    });
    tl.addLabel("content-in", "+=.1");
    // tl.to($container, {duration: .3, height: $wrapper.height()}, "content-in");
    tl.add(()=>
    {
      // console.log($wrapper.height());
      gsap.to($container, {duration: .3, height: $wrapper.height()});
    });
    tl.to($wrapper, {duration: .5, opacity: 1, ease: "power1.in"}, "content-in");
    tl.add(()=>
    {
      $container.css("height", "");
      _isLocking = false;
    });
  }

  private static _toWork(workThumbData: WorkThumbData, changePageToMe: boolean = true)
  {
    _currentWorkThumbData = workThumbData;

    _moreWorks.shuffle(_currentWorkThumbData.index);

    let workId = workThumbData.id,
       data = _workDataDic[workId];   

    this.build(data)

    if(changePageToMe) PageManager.toPage("work-detail");
  }

  static build(data: WorkDetailData)
  {
    let $container = $root.find(".work-detail-container>.wrapper");

    $container.find(".main-title").text(data.title);
    
    let kv: RwdPicture = Tools.seekComponentByClass("kv", $root);

    kv.setDataSrc(data.kv.pc, "pc");
    kv.setDataSrc(data.kv.mobile, "mobile");
    kv.load();
    

    let $feature = $container.find(".feature").empty();

    data.feature_list.forEach((value, index)=>
    {
      let $li = $(`
        <div class="li">
          <div class="label"></div>
          <div class="text"></div>
        </div>`);

      $li.find(".label").text(value.label);
      $li.find(".text").html(value.text);

      $feature.append($li);
    });

    let $media = $container.find(".media").empty();
    
    data.media_list.forEach((value, index)=>
    {
      if(value.type === "image")
      {
        let pic = RwdPicture.generate(value.image.pc, value.image.mobile);
        $media.append(pic.$root);
        pic.load();
      }
      else if(value.type === "youtube")
      {
        let $elem = $(`
        <div class="yt-container">
          <iframe class="ytplayer" type="text/html"
          src="https://www.youtube.com/embed/${value.youtube_id}?autoplay=0"
          frameborder="0"></iframe>
        </div>
        `);

        $media.append($elem);
      }
    });

  }
}